import styled from "styled-components"

export const AutoCompleteStyle = styled.input`
  outline: none;
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  border-width: 0px;
  margin-top: 20px;
  border: 0.2px solid #fafafa;
  font-size: 12px !important;
  width: 80%;
  box-shadow: 1px 1px 20px #efefef;
  -webkit-appearance: none;
`
