import React from "react"
import styled from "styled-components"
import { StarsWithPeople } from "../Stars/Stars"
import { useDispatch } from "react-redux"
import { openModal } from "../../redux/layoutSlice"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { device } from "../../constants/breakpoints"
import { trimLocationText } from "../../functions/trimLocationText"

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const SuggestedCardContainer = styled.div`
  background: white;
  width: 180px;
  width: 80%;
  max-width: 200px;
  margin: 20px;
  border-radius: 12px;
`

const Image = styled.div`
  cursor: pointer;
  background: url(${props => props.img}) center center/cover no-repeat;
  height: 150px;
  background-position: 0px -20px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
`

export const Title = styled.p`
  cursor: pointer;
  height: 40px;
  line-height: 20px;
  font-size: 18px;
  font-weight: 500;
  @media ${device.laptop} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 12px;
  }
`

export const Inner = styled.div`
  padding: 10px;
`
export const Icon = styled.img`
  margin-right: 10px;
  margin-top: 3px;
  height: 16px;
  width: auto;
  background-size: contain;
`

export const SuggestedCardButton = styled.div`
  cursor: pointer;
  color: white;
  background: ${({ theme }) => theme.midblue};
  padding: 10px;
  text-align: center;
  border: none;
  width: 120px;
  border-radius: 8px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  font-size: 14px;
`

export const LocationText = styled.p`
  font-size: 13px;
  margin-top: -3px;

  @media ${device.tablet} {
    font-size: 10px;
  }
  @media ${device.tabletSm} {
    margin-top: 0;
  }
`

const SuggestedCard = ({ doctor, ...restProps }) => {
  const dispatch = useDispatch()
  return (
    <SuggestedCardContainer {...restProps}>
      <Image onClick={() => navigateToChirurgo(doctor)} img={doctor.url_foto} />
      <Inner>
        <Title onClick={() => navigateToChirurgo(doctor)}>{doctor.nome}</Title>
        <StarsWithPeople
          count={doctor.rating}
          people={doctor.reviews_count}
          fontSize={12}
        />
        <Row>
          <Icon src={require("../../images/pin.png")} />
          <LocationText>{trimLocationText(doctor.list_provincie)}</LocationText>
        </Row>
        <SuggestedCardButton onClick={() => dispatch(openModal(doctor))}>
          Contatta
        </SuggestedCardButton>
      </Inner>
    </SuggestedCardContainer>
  )
}

export default SuggestedCard
