import React from "react"
import styled from "styled-components"

export const MobileFormInputMultiStyle = styled.textarea`
  box-shadow: 5px 5px 20px #efefef;
  border: none;
  padding: 15px;
  width: 100%;
  border-radius: 12px;
  -webkit-appearance: none;
  ::placeholder {
    color: #ccd0d4;
    opacity: 1;
    font-style: italic;
  }
`
const Block = styled.div`
  position: relative;
`

const Label = styled.div`
  bottom: 5px;
  position: absolute;
  right: 5px;
  color: #ccd0d4;
  font-size: 12px;
  font-style: italic;
  margin: 10px;
`

export default function MobileFormInputMultiLine({ ...restProps }) {
  return (
    <Block>
      <MobileFormInputMultiStyle {...restProps} />
      <Label>Min 150 Max 400</Label>
    </Block>
  )
}
