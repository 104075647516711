import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Star = styled.i`
  color: #ffcf5c;
`

export const WithPeopleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-top: -15px;

  &[title~="prepost"] {
    @media ${device.tablet} {
      justify-content: center;
    }
  }
`

export const PeopleCount = styled.p`
  font-weight: 200;
  color: #ccd0d4;
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 4px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 200px;
  align-items: center;
  align-content: center;
  margin-top: -10px;
`
