import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import { colors } from "../../constants/Colors"

import styled from "styled-components"
import MobileFormInput, {
  MobileFormInputStyle,
} from "../MobileFormInputs/MobileFormInput"
import AuthIcons from "../Atoms/AuthIcons"
import CustomButton from "../Atoms/Button"
import MobileFormAutoComplete from "../MobileFormInputs/MobileFormAutoComplete"
import { Trattamenti } from "../../constants/trattamenti"
import MobileFormInputMultiLine from "../MobileFormInputs/MobileFormInputMultiLine"
import MobileFormSelect from "../MobileFormSelect/MobileFormSelect"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchContactFormTrattamentiAsync,
  selectContactFormTrattamenti,
  selectFormStartupLoading,
  sendContactAsync,
} from "../../redux/contactFormSlice"
import LoadingViewSm from "../LoadingViewSm/LoadingViewSm"
import { openErrorToast } from "../../redux/toastSlice"
import ContactConfirm from "../ContactConfirm/ContactConfirm"
const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
    background: colors.BLUE,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))
const Inner = styled.div`
  padding: 30px;
  padding-bottom: 50px;
`

const P = styled.p`
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: 5px;
  font-weight: 500;
`
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Selections = [
  "Richiesta preventivo",
  "Informazioni",
  "Richiesta prenotazione visita",
]

export default function ContactModalMobile({
  open,
  close,
  chirurgo,
  passedTrattamento,
}) {
  const [send, setSend] = useState(false)
  const loadingStartUp = useSelector(selectFormStartupLoading)
  const classes = useStyles()
  const { register, handleSubmit, getValues } = useForm()
  const trattamenti = useSelector(selectContactFormTrattamenti)
  const [trattamento, setTrattamento] = React.useState(passedTrattamento)
  const [message, setMessage] = React.useState("")
  const [city, setCity] = React.useState("")
  const dispatch = useDispatch()
  const [selected, setSelected] = React.useState(0)

  useEffect(() => {
    if (chirurgo?.id_sys)
      dispatch(fetchContactFormTrattamentiAsync(chirurgo.id_sys))
  }, [chirurgo])

  useEffect(() => {
    setTrattamento(passedTrattamento)
  }, [passedTrattamento])

  if (!chirurgo?.id_sys) {
    return null
  }

  if (loadingStartUp) {
    return null
  }

  const onSubmit = ({ nome, cognome, email, telefono }) => {
    if (!city.length || !trattamento?.id_sys || !message.length) {
      dispatch(openErrorToast("Controlla i campi e riprova"))
    }
    close()
    dispatch(
      sendContactAsync(
        {
          nome,
          cognome,
          telefono,
          comune: city,
          email,
          id_chirurgo: chirurgo.id_sys,
          id_trattamento: trattamento.id_tra,
          messaggio: message,
          motivazione: Selections[selected],
        },
        () => setSend(true)
      )
    )
  }

  const renderContent = () => {
    if (send) {
      return (
        <ContactConfirm
          comune={city}
          id_tra={trattamento?.id_tra}
          checked={selected}
          values={getValues}
          onClose={close}
        />
      )
    }
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <MobileFormInputStyle
          placeholder={"Nome"}
          type="text"
          required
          {...register("nome")}
        />
        <MobileFormInputStyle
          placeholder={"Cognome"}
          type="text"
          style={{ marginTop: 20 }}
          required
          {...register("cognome")}
        />
        <MobileFormInputStyle
          {...register("email")}
          style={{ marginTop: 20 }}
          placeholder={"Email"}
          type={"email"}
          required
        />
        <MobileFormInputStyle
          {...register("telefono")}
          style={{ marginTop: 20 }}
          placeholder={"Telefono"}
          type={"phone"}
          required
        />
        <MobileFormAutoComplete
          style={{ marginTop: 20 }}
          value={city}
          onChange={(event, newInputValue) => {
            setCity(newInputValue)
          }}
          required
          getOptionLabel={option => option}
          options={chirurgo.list_provincie.split("-")}
          placeholder={"Città"}
        />
        <MobileFormAutoComplete
          style={{ marginTop: 20 }}
          value={trattamento}
          onChange={(event, newInputValue) => {
            setTrattamento(newInputValue)
          }}
          required
          getOptionLabel={option => option.trattamento}
          options={trattamenti}
          placeholder={"Trattamento"}
        />
        <P>Motivo di contatto</P>
        <MobileFormSelect
          setSelected={setSelected}
          selected={selected}
          items={Selections}
        />
        <MobileFormInputMultiLine
          style={{ marginTop: 30, minHeight: 300 }}
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder={"Scrivi un messaggio"}
        />
        <div style={{ textAlign: "center", marginTop: 40 }}>
          <CustomButton>Invia</CustomButton>
        </div>
      </form>
    )
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Modulo di contatto
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Inner>{renderContent()}</Inner>
    </Dialog>
  )
}
