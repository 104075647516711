import { Autocomplete } from "@material-ui/lab"
import React from "react"
import AutoComplete from "../AutoComplete/AutoComplete"
import { MobileFormInputStyle } from "./MobileFormInput"

export default function MobileFormAutoComplete({
  getOptionLabel,
  options,
  onChange,
  value,
  ...restProps
}) {
  return (
    <Autocomplete
      freeSolo
      getOptionLabel={getOptionLabel}
      options={options}
      value={value}
      onChange={onChange}
      renderInput={params => (
        <div style={{ textAlign: "center" }} ref={params.InputProps.ref}>
          <MobileFormInputStyle
            {...params.inputProps}
            {...restProps}
            type="text"
          />
        </div>
      )}
    />
  )
}
