import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 5px 5px 20px #efefef;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
`

const Indicator = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  background: ${props => (props.active ? props.theme.blue : "#CCD0D4")};
  transition: 0.1s all linear;
`

const Text = styled.b`
  color: ${props => props.theme.darkblue};
  font-weight: 400;
  margin: 0;
`

const SingleSelectItem = ({ i, selected, setSelected, item }) => {
  return (
    <Container onClick={() => setSelected(i)}>
      <Text>{item}</Text>
      <Indicator active={selected} />
    </Container>
  )
}

export default function MobileFormSelect({ selected, setSelected, items }) {
  return (
    <>
      {items.map((item, i) => (
        <SingleSelectItem
          i={i}
          item={item}
          selected={selected == i}
          setSelected={setSelected}
        />
      ))}
    </>
  )
}
