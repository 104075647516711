/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { createRef } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import FooterContainer from "../containers/footer"

import W3barContainer from "../containers/w3bar"
import MobileBar from "./MobileBar/MobileBar"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "../constants/global-styles"

import Aos from "aos"
import "aos/dist/aos.css"
import LoadingView from "./LoadingView/LoadingView"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchMenuAsync,
  selectChirurgia,
  selectMenuLoading, setPosition
} from "../redux/menuSlice"
import {
  closeToast,
  selectSeverity,
  selectText,
  selectToast,
} from "../redux/toastSlice"
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
  closeModal,
  selectLayoutChirurgo,
  selectModal,
  selectLayoutTrattamento,
  fetchLayoutTrattamentiAsync,
  fetchLayoutChirurghiAsync,
  fetchLayoutComuniAsync,
  selectLayoutLoading,
  selectLayoutChirurghi,
  selectLayoutComuni,
} from "../redux/layoutSlice"
import ContactModal from "./ContactFormModal/ContactFormModal"
import useWindowDimensions from "../hooks/useWindowDimensions"
import ContactModalMobile from "./ContactModalMobile/ContactModalMobile"
import { breakpoints } from "../constants/breakpoints"
import { useCurrentPosition } from "react-use-geolocation"
import CookieConsent from 'react-cookie-consent';
import { useJsApiLoader } from "@react-google-maps/api"

const theme = {
  blackblue: "#001229",
  darkblue: "#172d44",
  midblue: "#264a79",
  darkocean: "#85AFD4",
  ocean: "rgba(51, 122, 183, 0.2)",
  lightocean: "rgba(51, 122, 183, 0.1)",
  blue: "#337AB7",
  lightblue: "#5492E3",
  whitegray: "#f8f9fa",
  gray: "#797979",
}

const Layout = ({ children, contactModal = true }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectMenuLoading)
  const loading2 = useSelector(selectLayoutLoading)
  const toast = useSelector(selectToast)
  const text = useSelector(selectText)
  const severity = useSelector(selectSeverity)
  const modal = useSelector(selectModal)
  const chirurgo = useSelector(selectLayoutChirurgo)
  const trattamento = useSelector(selectLayoutTrattamento)
  const chirurghi = useSelector(selectLayoutChirurghi)
  const comuni = useSelector(selectLayoutComuni)
  const chirurgia = useSelector(selectChirurgia)
  const parentRef = createRef(null)
  const { width } = useWindowDimensions()
  const [position, error] = useCurrentPosition();

  React.useEffect(() => {
    if(position?.coords){
      dispatch(setPosition(position))
    }
  }, [position])

  React.useEffect(() => {
    Aos.init()
    if (!chirurgia?.trattamenti) dispatch(fetchMenuAsync())
    if (!trattamento?.id_sys) dispatch(fetchLayoutTrattamentiAsync())
    if (chirurghi.length == 0) dispatch(fetchLayoutChirurghiAsync())
    if (comuni?.length == 0) dispatch(fetchLayoutComuniAsync())
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    libraries: ['geometry', 'drawing'],
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    dispatch(closeToast())
  }

  if (loading || loading2 || !isLoaded) {
    return <LoadingView />
  }
  return (
    <div ref={parentRef}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <MobileBar />
        <W3barContainer />
        <div className={"spacer"} />
        {children}
        {contactModal ? (
          width > breakpoints.tablet ? (
            <ContactModal
              parentRef={parentRef}
              chirurgo={chirurgo}
              passedTrattamento={trattamento}
              open={modal}
              close={() => dispatch(closeModal())}
            />
          ) : (
            <ContactModalMobile
              chirurgo={chirurgo}
              passedTrattamento={trattamento}
              open={modal}
              close={() => dispatch(closeModal())}
            />
          )
        ) : null}

        <FooterContainer />
        <Snackbar
          anchorOrigin={{
            vertical: width < 767 ? "top" : "bottom",
            horizontal: "center",
          }}
          open={toast}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity}>
            {text}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
