import { Modal, Slide } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import { colors } from "../../constants/Colors"
import { get, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import {
  fetchContactFormTrattamentiAsync,
  selectContactFormTrattamenti,
  selectFormLoading,
  selectFormStartupLoading,
  sendContactAsync,
} from "../../redux/contactFormSlice"
import { CustomButtonInput } from "../Atoms/Button"
import ContactForm from "../Form/Form"
import { Input, InputLarge } from "../Form/styles/frm"
import GlobalCheckBox from "../GlobalCheckBox/GlobalCheckBox"
import LoadingViewSm from "../LoadingViewSm/LoadingViewSm"
import { openErrorToast } from "../../redux/toastSlice"
import { device } from "../../constants/breakpoints"
import { selectCurrentUser } from "../../redux/userSlice"
import ContactConfirm from "../ContactConfirm/ContactConfirm"
import { navigate } from "gatsby-link"

const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  min-width: 1200px;
  background: #f7f7f7;
  justify-content: center;
  align-items: center;

  text-align: center;
  border-radius: 8px !important;
  padding-bottom: 60px;
  z-index: 100;

  @media ${device.laptop} {
    min-width: 700px;
  }

  @media ${device.desktop} {
    min-width: 900px;
  }

  @media ${device.tablet} {
    min-width: 300px;
  }
`

const Inner = styled.div`
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const CloseBtn = styled.p`
  font-size: 24px;
  padding-right: 20px;
  cursor: pointer;
  @media ${device.tablet} {
    font-size: 14px;
    margin-right: -5px;
  }
`

const Header = styled.div`
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  padding-top: 10px !important;
  width: 100%;

  background: ${props => props.theme.darkblue};
  font-size: 28px;
  text-align: center;
  font-weight: 400;
  display: flex;
  color: white;
  align-content: center;
  @media ${device.tablet} {
    font-size: 14px;
    padding-top: 20px !important;
  }
`

export default function ContactModal({
  parentRef,
  open,
  close,
  chirurgo = { nome: "gianni", id_sys: 1, list_provincie: "Milano" },
  passedTrattamento,
}) {
  const loadingStartUp = useSelector(selectFormStartupLoading)
  const classes = useStyles()
  const sendRef = React.useRef(null)
  const trattamenti = useSelector(selectContactFormTrattamenti)
  const { register, handleSubmit, getValues } = useForm()
  const [city, setCity] = useState("")
  const [cityError, setCityError] = useState(false)
  const [treatment, setTreatment] = useState(passedTrattamento)
  const [treatmentError, setTreatmentError] = useState(false)
  const [checked, setChecked] = useState(0)
  const dispatch = useDispatch()
  const loading = useSelector(selectFormLoading)
  const user = useSelector(selectCurrentUser)
  const [send, setSend] = useState(false)

  useEffect(() => {
    if (chirurgo?.id_sys)
      dispatch(fetchContactFormTrattamentiAsync(chirurgo.id_sys))
  }, [chirurgo])

  useEffect(() => {
    console.log("passedTrattamento", passedTrattamento)

    setTreatment(passedTrattamento)
  }, [passedTrattamento])

  if (!chirurgo?.id_sys) {
    return null
  }
  if (loadingStartUp) {
    return null
  }

  const onSubmit = ({ nome, cognome, telefono, email, messaggio }) => {
    if (isNaN(city?.id)) {
      dispatch(openErrorToast("la sede inserita non è valida"))
      return
    } else if (!treatment?.id_sys) {
      dispatch(openErrorToast("il trattamento inserito non è valido"))
      return
    }

    dispatch(
      sendContactAsync(
        {
          id_user: user?.user_id,
          nome,
          cognome,
          telefono,
          comune: city.label,
          email,
          id_chirurgo: chirurgo.id_sys,
          id_trattamento: treatment.id_tra,
          messaggio,
          motivazione:
            checked == 0
              ? "Preventivo"
              : checked == 1
              ? "Visita"
              : "Videoconsulto",
        },
        () => {
          navigate("/confirmation" + "?id_chi=" + chirurgo.id_sys, {
            state: {
              city,
              id_tra: treatment.id_tra,
              id_chi: chirurgo.id_sys,
              sendPayload: {
                id_user: user?.user_id,
                nome,
                cognome,
                telefono,
                comune: city.label,
                email,
                id_trattamento: treatment.id_tra,
                messaggio,
                motivazione:
                  checked == 0
                    ? "Preventivo"
                    : checked == 1
                    ? "Visita"
                    : "Videoconsulto",
              },
            },
          })
          close()
        }
      )
    )
  }

  const renderContent = () => {
    if (send)
      return (
        <ContactConfirm
          comune={city}
          id_tra={treatment?.id_tra}
          checked={checked}
          values={getValues}
          onClose={() => {
            close()
            setSend(false)
          }}
          id_chi={chirurgo.id_sys}
          sendToChirurgoId={sendRef.current}
        />
      )
    return (
      <ContactForm style={{ background: "#f7f7f7" }}>
        <ContactForm.Wrapper onSubmit={handleSubmit(onSubmit)}>
          <ContactForm.Row>
            <Input
              required
              type="text"
              name="name"
              {...register("nome")}
              placeholder="Nome"
            />
            <Input
              required
              type="text"
              name="cognome"
              {...register("cognome")}
              placeholder="Cognome"
            />
          </ContactForm.Row>
          <ContactForm.Row>
            <Input
              required
              type="phone"
              name="phone"
              {...register("telefono")}
              placeholder="Telefono"
            />
            <ContactForm.AutoComplete
              freeSolo
              options={chirurgo.list_provincie
                .split("-")
                .map((pr, i) => ({ id: i, label: pr }))}
              getOptionLabel={option => option.label}
              value={city}
              onChange={(event, newValue) => {
                console.log("newValue", newValue)
                setCity(newValue)
              }}
              renderInput={params => (
                <ContactForm.InputAuto
                  error={cityError}
                  {...params}
                  label="Seleziona una sede"
                />
              )}
            />
          </ContactForm.Row>
          <ContactForm.Row>
            <Input type="email" {...register("email")} placeholder="Email" />
            <ContactForm.AutoComplete
              freeSolo
              options={trattamenti}
              getOptionLabel={option => option.trattamento}
              value={treatment}
              onChange={(event, newInputValue) => {
                setTreatment(newInputValue)
              }}
              renderInput={params => (
                <ContactForm.InputAuto
                  error={treatmentError}
                  {...params}
                  label="Trattamento"
                />
              )}
            />
          </ContactForm.Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: 10,
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            <GlobalCheckBox
              onClick={() => setChecked(0)}
              checked={checked == 0}
              id={"check1"}
              label={"Richiesta Preventivo"}
            />
            <GlobalCheckBox
              onClick={() => setChecked(1)}
              checked={checked == 1}
              id={"check2"}
              label={"Richiesta di prestazione visita"}
            />
            {/*    <GlobalCheckBox
                    onClick={() => setChecked(2)}
                    checked={checked == 2}
                    id={"check3"}
                    label={"Videoconsulto"}
                  />
                  */}
          </div>
          <InputLarge required {...register("messaggio")} />
          <CustomButtonInput
            style={{ marginTop: 20 }}
            color={colors.MID_BLUE}
            value="Invia"
            type="submit"
          />
        </ContactForm.Wrapper>
      </ContactForm>
    )
  }

  const getHeaderString = () => {
    if (send) {
      return "Richiesta inviata!"
    }
    const articolo = chirurgo.sesso == "M" ? " il " : " la "
    const nome = chirurgo.nome.toLowerCase().startsWith("dott.")
      ? chirurgo.nome
      : "Dott. " + chirurgo.nome

    return `Contatta ${articolo} ${nome}`
  }
  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={open}
      onClose={close}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      container={() => parentRef.current}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Wrapper>
          <Header>
            <CloseBtn style={{ marginRight: "auto", visibility: "hidden" }}>
              x
            </CloseBtn>
            <b>{getHeaderString()}</b>
            <CloseBtn
              onClick={() => {
                close()
                setSend(false)
              }}
              style={{ marginLeft: "auto", color: "white" }}
            >
              x
            </CloseBtn>
          </Header>
          <Inner>{renderContent()}</Inner>
        </Wrapper>
      </Slide>
    </Modal>
  )
}
