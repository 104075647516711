export const Chirurgia = {
  NASO: ["Rinoplastica", "Settoplastica"],
  VISO: [
    "Lifting del viso",
    "Blefaroplastica",
    "Otoplastica",
    "Mentoplastica",
    "Lipofilling viso",
  ],
  SENO: [
    "Mastoplastica additiva",
    "Mastpolastica riduttiva",
    "Mastopessi",
    "Sostituzione protesi",
    "Lipofilling seno",
  ],
  CORPO: [
    "Addominoplastica",
    "Liposuzione",
    "Gluteoplastica",
    "Lipostruttura",
    "Ginecomastia",
  ],
  CAPELLI: ["Trapianto capelli FUE", "Trapianto capelli FUT"],
  INTIMO: [
    "Falloplastica",
    "Vaginoplastica",
    "Labioplastica",
    "Ricostruzione dell'imene",
  ],
}

export const Medicina = {
  VISO: [
    "Aumento labbra",
    "Acido Ialuronico",
    "Fili di sospensione",
    "Laser frazionato",
    "Rimozione macchie",
    "Rinofiller",
    "PRP viso",
    "Peeling Chimico",
    "Needling",
    "Plexer",
  ],
  CORPO: [
    "Cavitazione",
    "Mesoterapia",
    "Radiofrequenza",
    "Laser frazionato",
    "Iperidrosi ascellare",
    "Vene varicose",
    "Nevi",
  ],
  CAPELLI: ["PRP capelli", "Tricopigmentazione"],
  INTIMO: ["Ringiovanimento vaginale", "Ingrossamento pene"],
}

export const Trattamenti = [
  "Acido Ialuronico",
  "Addominoplastica",
  "Aumento labbra",
  "Blefaroplastica",
  "Cavitazione",
  "Falloplastica",
  "Fili di sospensione",
  "Ginecomastia",
  "Gluteoplastica",
  "Ingrossamento pene",
  "Iperidrosi ascellare",
  "Labioplastica",
  "Laser frazionato",
  "Laser frazionato",
  "Lifting del viso",
  "Lipofilling seno",
  "Lipofilling viso",
  "Lipostruttura",
  "Liposuzione",
  "Mastopessi",
  "Mastoplastica additiva",
  "Mastpolastica riduttiva",
  "Mentoplastica",
  "Mesoterapia",
  "Needling",
  "Nevi",
  "Otoplastica",
  "PRP capelli",
  "PRP viso",
  "Peeling Chimico",
  "Plexer",
  "Radiofrequenza",
  "Ricostruzione dell'imene",
  "Rimozione macchie",
  "Ringiovanimento vaginale",
  "Rinofiller",
  "Rinoplastica",
  "Settoplastica",
  "Sostituzione protesi",
  "Trapianto capelli FUE",
  "Trapianto capelli FUT",
  "Tricopigmentazione",
  "Vaginoplastica",
  "Vene varicose",
]

export const CHIRURGIA_FLAT = [
  "Rinoplastica",
  "Settoplastica",
  "Lifting del viso",
  "Blefaroplastica",
  "Otoplastica",
  "Mentoplastica",
  "Lipofilling viso",
  "Mastoplastica additiva",
  "Mastpolastica riduttiva",
  "Mastopessi",
  "Sostituzione protesi",
  "Lipofilling seno",
  "Addominoplastica",
  "Liposuzione",
  "Gluteoplastica",
  "Lipostruttura",
  "Ginecomastia",
  "Trapianto capelli FUE",
  "Trapianto capelli FUT",
  "Falloplastica",
  "Vaginoplastica",
  "Labioplastica",
  "Ricostruzione dell'imene",
]

export const MEDICINA_FLAT = [
  "Aumento labbra",
  "Acido Ialuronico",
  "Fili di sospensione",
  "Laser frazionato",
  "Rimozione macchie",
  "Rinofiller",
  "PRP viso",
  "Peeling Chimico",
  "Needling",
  "Plexer",
  "Cavitazione",
  "Mesoterapia",
  "Radiofrequenza",
  "Laser frazionato",
  "Iperidrosi ascellare",
  "Vene varicose",
  "Nevi",
  "PRP capelli",
  "Tricopigmentazione",
  "Ringiovanimento vaginale",
  "Ingrossamento pene",
]
