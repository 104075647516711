import { Autocomplete } from "@material-ui/lab"
import React from "react"
import { MenuItems } from "../../constants/MenuItems"
import { AutoCompleteStyle } from "./styles/AutoComplete.styled"

export default function AutoComplete({
  setValue,
  value,
  options,
  getOptionLabel,
  ...restProps
}) {
  return (
    <Autocomplete
      freeSolo
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={params => (
        <div ref={params.InputProps.ref}>
          <AutoCompleteStyle
            inputValue={value}
            onInputChange={(event, newInputValue) => {
              setValue(newInputValue)
            }}
            {...params.inputProps}
            {...restProps}
            type="text"
          />
        </div>
      )}
    />
  )
}
