import React, { useEffect, useState } from "react"
import styled from "styled-components"
import CustomButton from "../Atoms/Button"
import { colors } from "../../constants/Colors"
import { sendRequestAsync } from "../../functions/sendRequestAsync"
import { POST_CHIRUGHI_SEARCH, POST_MESSAGE } from "../../constants/api"
import SuggestedCard, {
  SuggestedCardButton,
  SuggestedCardContainer,
} from "../SuggestedCol/SuggestedCard"
import GlobalIcon, { Icon } from "../Atoms/GlobalIcon"
import { device } from "../../constants/breakpoints"
import LoadingView from "../LoadingView/LoadingView"
import { navigate } from "gatsby-link"

const ConfirmSection = styled.div`
  text-align: center;

  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 500px;
  h4 {
    color: ${colors.BLACK_BLUE};
  }
  @media ${device.tablet} {
    width: 100%;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 250px;
  padding-bottom: 200px;
`

const Chirurghi = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  max-height: 320px;
  margin: auto;
  justify-content: center;
  p {
    text-align: left !important;
  }
`

const CardWrapper = styled.div`
  filter: ${props => (props.selected ? `opacity(100%)` : ` opacity(80%)`)};
  cursor: pointer;

  ${Icon} {
    display: ${props => (props.selected ? `block` : `none`)};
    &[title~="nohide"] {
      display: ${props => (props.selected ? `none` : `block`)};
    }
    position: absolute;
    top: 20px;
    right: 15px;
  }

  ${SuggestedCardButton} {
    display: none;
  }
  ${SuggestedCardContainer} {
    width: 200px;
    pointer-events: none;
  }
`

export default function ContactConfirm({
  onClose,
  id_tra,
  id_chi,
  sendToChirurgoId,
}) {
  const [chirurghi, setChirurghi] = useState([])
  const [chirurghiSelected, setChirurghiSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [startUpLoading, setStartUpLoading] = useState(false)
  const [confirmText, setConfirmText] = useState(null)

  useEffect(() => {
    getChirurghi()
  }, [])

  const onSend = async () => {
    setLoading(true)
    const promises = chirurghiSelected.map(id => sendToChirurgoId(id))

    try {
      await Promise.all(promises)

      const url = () => {
        const base = "/sconfirmation"
        const length = chirurghiSelected.length
        if (length === 1) {
          return `${base}?id_chirurgo1=${chirurghiSelected[0]}`
        } else if (length === 2) {
          return `${base}?id_chirurgo1=${chirurghiSelected[0]}&id_chirurgo2=${chirurghiSelected[1]}`
        } else if (length === 3) {
          return `${base}?id_chirurgo1=${chirurghiSelected[0]}&id_chirurgo2=${chirurghiSelected[1]}&id_chirurgo3=${chirurghiSelected[2]}`
        } else if (length === 4) {
          return `${base}?id_chirurgo1=${chirurghiSelected[0]}&id_chirurgo2=${chirurghiSelected[1]}&id_chirurgo3=${chirurghiSelected[2]}&id_chirurgo4=${chirurghiSelected[3]}`
        }
      }
      navigate(url(), {
        state: {
          count: chirurghiSelected.length,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getChirurghi = async () => {
    setStartUpLoading(true)
    await sendRequestAsync(
      POST_CHIRUGHI_SEARCH,
      {
        table_page: 1,
        id_trattamento: id_tra,
      },
      data => {
        setChirurghi(data.fun_data)
        setStartUpLoading(false)
      },
      () => {
        setChirurghi([])
      },
      data => data.fun_success
    )
  }

  const isSelected = id => {
    return chirurghiSelected.includes(id)
  }

  const handleClick = id => {
    if (chirurghiSelected.includes(id)) {
      setChirurghiSelected(chirurghiSelected.filter(c => c !== id))
    } else {
      setChirurghiSelected([...chirurghiSelected, id])
    }
  }
  if (startUpLoading) {
    return <LoadingView />
  }
  if (confirmText) {
    return (
      <div style={{ padding: 50, textAlign: "center" }}>
        <span style={{ fontSize: 40 }}>{confirmText}</span>
        <br />
        <br />
        <CustomButton onClick={onClose}>Chiudi</CustomButton>
      </div>
    )
  }
  return (
    <div>
      <ConfirmSection>
        <h1>Grazie!</h1>
        <p>Abbiamo inoltrato la tua richiesta, sarai contattato a breve!</p>
      </ConfirmSection>
      <ConfirmSection>
        <h4>Vuoi inviare la richiesta anche ad altri chirurghi?</h4>
      </ConfirmSection>
      <Chirurghi>
        {chirurghi
          .filter(chi => id_chi !== chi.id_sys)
          .slice(0, 4)
          .map(chi => (
            <CardWrapper
              selected={isSelected(chi.id_sys)}
              onClick={() => handleClick(chi.id_sys)}
            >
              <GlobalIcon
                style={{ height: 30 }}
                title="nohide"
                img={require("../../images/nohide.png")}
              />
              <GlobalIcon
                style={{ height: 30 }}
                img={require("../../images/visto.png")}
              />
              <SuggestedCard doctor={chi} />
            </CardWrapper>
          ))}
      </Chirurghi>
      <Buttons style={{ marginTop: 30 }}>
        <CustomButton
          onClick={onClose}
          style={{
            background: "#f2f2f2",
            color: colors.BLACK_BLUE,
            fontWeight: "bold",
          }}
        >
          SALTA
        </CustomButton>
        <div style={{ height: 20 }} />
        <CustomButton style={{ fontWeight: "bold" }} onClick={onSend}>
          INVIA AI CHIRURGHI SELEZIONATI
        </CustomButton>
      </Buttons>
    </div>
  )
}
