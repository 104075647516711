import React, { useState } from "react"
import { Star, Container, PeopleCount, WithPeopleContainer } from "./styles/Str"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons"
import styled from "styled-components"

const StarStyle = styled(FontAwesomeIcon)`
  color: #ffcf5c;
  font-size: ${props => props.fontSize}px;
  margin-right: 3px;
`

const FullStar = ({ ...restProps }) => {
  return <StarStyle fontSize={14} icon={faStar} {...restProps} />
}

const EmptyStar = ({ ...restProps }) => {
  return <StarStyle fontSize={14} {...restProps} icon={emptyStar} />
}

export const StarsWithPeople = ({
  people = 32,
  fontSize = 14,
  count,
  ...restProps
}) => {
  return null;
  return (
    <WithPeopleContainer {...restProps}>
      <Stars count={count} fontSize={fontSize} />
      <PeopleCount style={{ fontSize }}>{"(" + people + ")"}</PeopleCount>
    </WithPeopleContainer>
  )
}

export const StarsInput = ({ count, setRating, ...restProps }) => {
  return (
    <Container {...restProps}>
      {count >= 1 ? (
        <FullStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(1)}
          {...restProps}
        />
      ) : (
        <EmptyStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(1)}
          {...restProps}
        />
      )}
      {count >= 2 ? (
        <FullStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(2)}
          {...restProps}
          {...restProps}
        />
      ) : (
        <EmptyStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(2)}
          {...restProps}
        />
      )}
      {count >= 3 ? (
        <FullStar
          style={{ cursor: "pointer" }}
          {...restProps}
          onClick={() => setRating(3)}
          {...restProps}
        />
      ) : (
        <EmptyStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(3)}
          {...restProps}
        />
      )}
      {count >= 4 ? (
        <FullStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(4)}
          {...restProps}
          {...restProps}
        />
      ) : (
        <EmptyStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(4)}
          {...restProps}
        />
      )}
      {count == 5 ? (
        <FullStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(5)}
          {...restProps}
          {...restProps}
        />
      ) : (
        <EmptyStar
          style={{ cursor: "pointer" }}
          onClick={() => setRating(5)}
          {...restProps}
        />
      )}
    </Container>
  )
}

const Stars = ({ count = 3, fontSize = 14, ...restProps }) => {
  return (
    <Container {...restProps}>
      {count >= 1 ? (
        <FullStar fontSize={fontSize} />
      ) : (
        <EmptyStar fontSize={fontSize} />
      )}
      {count >= 2 ? (
        <FullStar fontSize={fontSize} />
      ) : (
        <EmptyStar fontSize={fontSize} />
      )}
      {count >= 3 ? (
        <FullStar fontSize={fontSize} />
      ) : (
        <EmptyStar fontSize={fontSize} />
      )}
      {count >= 4 ? (
        <FullStar fontSize={fontSize} />
      ) : (
        <EmptyStar fontSize={fontSize} />
      )}
      {count == 5 ? (
        <FullStar fontSize={fontSize} />
      ) : (
        <EmptyStar fontSize={fontSize} />
      )}
    </Container>
  )
}

export default Stars
